@font-face {
  font-family: "HelveticaNeue";
  src: url("./helvetica-neue/HelveticaNeueLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("./helvetica-neue/HelveticaNeueMedium.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* Add other font weights and styles if necessary */

@font-face {
  font-family: "SctoGroteskA";
  src: url("./scto-grotesk-a/Scto Grotesk A Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SctoGroteskA";
  src: url("./scto-grotesk-a/Scto Grotesk A Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

/* Add other font weights and styles if necessary */
